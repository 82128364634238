// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
    AUTH: '/auth',
    AUTH_DEMO: '/auth-demo',
    DASHBOARD: '/dashboard',
    AUTHENTICATION: '/authentication',
    USER_MANAGEMENT: '/site-administration',
};

// ----------------------------------------------------------------------

export const paths = {
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    pricing: '/pricing',
    payment: '/payment',
    about: '/about-us',
    contact: '/contact-us',
    faqs: '/faqs',
    page403: '/403',
    page404: '/404',
    page500: '/500',
    components: '/components',
    docs: 'https://docs.minimals.cc',
    changelog: 'https://docs.minimals.cc/changelog',
    zoneUI: 'https://mui.com/store/items/zone-landing-page/',
    minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
    freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
    figma: 'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
    product: {
        root: `/product`,
        checkout: `/product/checkout`,
        details: (id) => `/product/${id}`,
        demo: {
            details: `/product/${MOCK_ID}`,
        },
    },
    post: {
        root: `/post`,
        details: (title) => `/post/${paramCase(title)}`,
        demo: {
            details: `/post/${paramCase(MOCK_TITLE)}`,
        },
    },
    // AUTH
    auth: {
        amplify: {
            login: `${ROOTS.AUTH}/amplify/login`,
            verify: `${ROOTS.AUTH}/amplify/verify`,
            register: `${ROOTS.AUTH}/amplify/register`,
            newPassword: `${ROOTS.AUTH}/amplify/new-password`,
            forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
        },
        jwt: {
            login: `${ROOTS.AUTH}/jwt/login`,
            // loginUser: `${ROOTS.AUTHENTICATION}/auth-login`,
            register: `${ROOTS.AUTH}/jwt/register`,
        },
        firebase: {
            login: `${ROOTS.AUTH}/firebase/login`,
            verify: `${ROOTS.AUTH}/firebase/verify`,
            register: `${ROOTS.AUTH}/firebase/register`,
            forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
        },
        auth0: {
            login: `${ROOTS.AUTH}/auth0/login`,
        },
    },
    authDemo: {
        classic: {
            login: `${ROOTS.AUTH_DEMO}/classic/login`,
            register: `${ROOTS.AUTH_DEMO}/classic/register`,
            forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
            newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
            verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
        },
        modern: {
            login: `${ROOTS.AUTH_DEMO}/modern/login`,
            register: `${ROOTS.AUTH_DEMO}/modern/register`,
            forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
            newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
            verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
        },
    },
    // Authentication
    authentication: {
        authlogin: `${ROOTS.AUTHENTICATION}/auth-login`,
        authregister: `${ROOTS.AUTHENTICATION}/auth-register`,
        authforgotPassword: `${ROOTS.AUTHENTICATION}/auth-forgot-password`,
        authchangepassword: `${ROOTS.AUTHENTICATION}/auth-change-password`,
        authnewPassword: `${ROOTS.AUTHENTICATION}/auth-new-password`,
        authverify: `${ROOTS.AUTHENTICATION}/auth-verify`,
        authmfa: `${ROOTS.AUTHENTICATION}/auth-mfa`,
        authverifymfa: `${ROOTS.AUTHENTICATION}/auth-verify-mfa`,
    },
    // DASHBOARD
    dashboard: {
        root: ROOTS.DASHBOARD,
        mail: `${ROOTS.DASHBOARD}/mail`,
        chat: `${ROOTS.DASHBOARD}/chat`,
        blank: `${ROOTS.DASHBOARD}/blank`,
        kanban: `${ROOTS.DASHBOARD}/kanban`,
        calendar: `${ROOTS.DASHBOARD}/calendar`,
        fileManager: `${ROOTS.DASHBOARD}/file-manager`,
        permission: `${ROOTS.DASHBOARD}/permission`,
        general: {
            app: `${ROOTS.DASHBOARD}/app`,
            ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
            analytics: `${ROOTS.DASHBOARD}/analytics`,
            banking: `${ROOTS.DASHBOARD}/banking`,
            booking: `${ROOTS.DASHBOARD}/booking`,
            file: `${ROOTS.DASHBOARD}/file`,
        },
        // user
        user: {
            root: `${ROOTS.DASHBOARD}/site-administration/user`,
            new: `${ROOTS.DASHBOARD}/site-administration/user/new`,
            list: `${ROOTS.DASHBOARD}/site-administration/user/list`,
            cards: `${ROOTS.DASHBOARD}/site-administration/user/cards`,
            profile: (id) => `${ROOTS.DASHBOARD}/site-administration/user/${id}/profile`,
            account: `${ROOTS.DASHBOARD}/site-administration/user/account`,
            edit: `${ROOTS.DASHBOARD}/site-administration/user/edit`,
            demo: {
                edit: `${ROOTS.DASHBOARD}/site-administration/user/${MOCK_ID}/edit`,
            },
        },

        roles: {
            root: `${ROOTS.DASHBOARD}/site-administration/roles`,
            list: `${ROOTS.DASHBOARD}/site-administration/roles/list`,
            edit: `${ROOTS.DASHBOARD}/site-administration/roles/edit`,
            create: `${ROOTS.DASHBOARD}/site-administration/roles/create`,
        },

        permissions: {
            root: `${ROOTS.DASHBOARD}/site-administration/permissions`,
            list: `${ROOTS.DASHBOARD}/site-administration/permissions/list`,
            edit: `${ROOTS.DASHBOARD}/site-administration/permissions/edit`,
            create: `${ROOTS.DASHBOARD}/site-administration/permissions/create`,
        },

        organisation: {
            root: `${ROOTS.DASHBOARD}/uem-setting/organisation`,
            edit: `${ROOTS.DASHBOARD}/uem-setting/organisation/edit`,
        },

        enterprises: {
            root: `${ROOTS.DASHBOARD}/uem-setting/enterprises`,
            details: (id) => `${ROOTS.DASHBOARD}/uem-setting/enterprises/details/${id}`,
            list: `${ROOTS.DASHBOARD}/uem-setting/enterprises/list`,
            edit: (id) => `${ROOTS.DASHBOARD}/uem-setting/enterprises/edit/${id}`,
            create: `${ROOTS.DASHBOARD}/uem-setting/enterprises/create`,
            createent: (id) => `${ROOTS.DASHBOARD}/uem-setting/enterprises/create/${id}`,
        },

        organisationhierarchy: {
            root: `${ROOTS.DASHBOARD}/uem-setting/organisation-hierarchy`,
            list: `${ROOTS.DASHBOARD}/uem-setting/organisation-hierarchy/list`,
            edit: `${ROOTS.DASHBOARD}/uem-setting/organisation-hierarchy/edit`,
            create: `${ROOTS.DASHBOARD}/uem-setting/organisation-hierarchy/create`,
        },
        // uem
        setting: {
            root: `${ROOTS.DASHBOARD}/uem-setting/setting`,
            edit: `${ROOTS.DASHBOARD}/uem-setting/setting/edit`,
        },

        termsofusedoc: {
            root: `${ROOTS.DASHBOARD}/uem-setting/terms-of-use-doc`,
            list: `${ROOTS.DASHBOARD}/uem-setting/terms-of-use-doc/list`,
            edit: `${ROOTS.DASHBOARD}/uem-setting/terms-of-use-doc/edit`,
            create: `${ROOTS.DASHBOARD}/uem-setting/terms-of-use-doc/create`,
        },

        // application-management
        apprepository: {
            root: `${ROOTS.DASHBOARD}/application-management/app-repository`,
            root2: `${ROOTS.DASHBOARD}/application-management/app-stores`,
            root3: `${ROOTS.DASHBOARD}/application-management/app-stores/google`,
            createtest: `${ROOTS.DASHBOARD}/application-management/app-stores/create-test`,
            applist: `${ROOTS.DASHBOARD}/application-management/app-repository/app-list`,
            // webapps: `${ROOTS.DASHBOARD}/application-management/app-repository/web-apps`,

            publicapps: `${ROOTS.DASHBOARD}/application-management/app-stores/google/publicapps`,
            privateapps: `${ROOTS.DASHBOARD}/application-management/app-stores/google/privateapps`,
            webapps: `${ROOTS.DASHBOARD}/application-management/app-stores/google/webapps`,
            organizeapps: `${ROOTS.DASHBOARD}/application-management/app-stores/google/organize-apps`,
            orgprivatestore: `${ROOTS.DASHBOARD}/application-management/app-stores/orgstore`,
            detail: `${ROOTS.DASHBOARD}/application-management/app-repository/detail`,
            details: (appId) =>
                `${ROOTS.DASHBOARD}/application-management/app-repository/${appId}/detail`,
            edit: `${ROOTS.DASHBOARD}/application-management/app-repository/edit`,
            create: `${ROOTS.DASHBOARD}/application-management/app-repository/create`,
            distribute: `${ROOTS.DASHBOARD}/application-management/app-repository/distribute-apps`,
            appsecurity: `${ROOTS.DASHBOARD}/application-management/app-repository/app-security`,
        },

        appupdatepolicy: {
            root: `${ROOTS.DASHBOARD}/application-management/app-update-policy`,
            edit: `${ROOTS.DASHBOARD}/application-management/app-update-policy/edit`,
            list: `${ROOTS.DASHBOARD}/application-management/app-update-policy/list`,
            create: `${ROOTS.DASHBOARD}/application-management/app-update-policy/create`,
        },

        // content-management

        content: {
            root: `${ROOTS.DASHBOARD}/content-management/content`,
            list: `${ROOTS.DASHBOARD}/content-management/content/list`,
            edit: `${ROOTS.DASHBOARD}/content-management/content/edit`,
            create: `${ROOTS.DASHBOARD}/content-management/content/create`,
        },

        // device-mamagement

        devices: {
            root: `${ROOTS.DASHBOARD}/device-management/devices`,
            detail: (id) => `${ROOTS.DASHBOARD}/device-management/devices/${id}/detail`,
            list: `${ROOTS.DASHBOARD}/device-management/devices/list`,
            edit: `${ROOTS.DASHBOARD}/device-management/devices/edit`,
            details: (id) => `${ROOTS.DASHBOARD}/device-management/devices/${id}`,
            bulkedit: `${ROOTS.DASHBOARD}/device-management/devices/bulk-edit`,
            create: `${ROOTS.DASHBOARD}/device-management/devices/create`,
            newcreate: `${ROOTS.DASHBOARD}/device-management/devices/newcreate`,
        },

        profiles: {
            root: `${ROOTS.DASHBOARD}/device-security/profiles`,
            list: `${ROOTS.DASHBOARD}/device-security/profiles/list`,
            edit: `${ROOTS.DASHBOARD}/device-security/profiles/edit`,
            create: `${ROOTS.DASHBOARD}/device-security/profiles/create`,
            detail: `${ROOTS.DASHBOARD}/device-security/profiles/details`,
            workprofile: `${ROOTS.DASHBOARD}/device-security/profiles/workprofile`,
        },

        certificates: {
            root: `${ROOTS.DASHBOARD}/device-management/certificates`,
            list: `${ROOTS.DASHBOARD}/device-management/certificates/list`,
            edit: `${ROOTS.DASHBOARD}/device-management/certificates/edit`,
            create: `${ROOTS.DASHBOARD}/device-management/certificates/create`,
            caserver: `${ROOTS.DASHBOARD}/device-management/certificates/ca-server`,
            templates: `${ROOTS.DASHBOARD}/device-management/certificates/templates`,
        },

        devicealerts: {
            root: `${ROOTS.DASHBOARD}/device-management/device-alerts`,
            edit: `${ROOTS.DASHBOARD}/device-management/device-alerts/edit`,
            create: `${ROOTS.DASHBOARD}/device-management/device-alerts/create`,
        },

        osupdates: {
            root: `${ROOTS.DASHBOARD}/device-management/os-updates`,
            list: `${ROOTS.DASHBOARD}/device-management/os-updates/list`,
            edit: `${ROOTS.DASHBOARD}/device-management/os-updates/edit`,
            create: `${ROOTS.DASHBOARD}/device-management/os-updates/create`,
        },

        enterprisesandgroups: {
            root: `${ROOTS.DASHBOARD}/device-management/enterprises-and-groups`,
        },

        scheduledevicescan: {
            root: `${ROOTS.DASHBOARD}/device-management/schedule-device-scan`,
            scannow: `${ROOTS.DASHBOARD}/device-management/schedule-device-scan/scan-now`,
        },

        // location-management

        // geofencepolicy: {
        //     root: `${ROOTS.DASHBOARD}/location-management/geo-fence-policy`,
        //     list: `${ROOTS.DASHBOARD}/location-management/geo-fence-policy/list`,
        //     edit: `${ROOTS.DASHBOARD}/location-management/geo-fence-policy/edit`,
        //     create: `${ROOTS.DASHBOARD}/location-management/geo-fence-policy/create`,
        //     geomaps: `${ROOTS.DASHBOARD}/location-management/geo-fence-policy/Maps`,
        // },

        // geofencerepository: {
        //     root: `${ROOTS.DASHBOARD}/location-management/geo-fence-repository`,
        //     list: `${ROOTS.DASHBOARD}/location-management/geo-fence-repository/list`,
        //     edit: `${ROOTS.DASHBOARD}/location-management/geo-fence-repository/edit`,
        //     create: `${ROOTS.DASHBOARD}/location-management/geo-fence-repository/create`,
        // },

        geofencing_v2: {
            root: `${ROOTS.DASHBOARD}/location-management/v2/geo-fencing`,
            create: `${ROOTS.DASHBOARD}/location-management/v2/geo-fencing/create`,
            cards: `${ROOTS.DASHBOARD}/location-management/v2/geo-fencing/cards`,
            logs: `${ROOTS.DASHBOARD}/location-management/v2/geo-fencing/logs`,
            apply: `${ROOTS.DASHBOARD}/location-management/v2/geo-fencing/apply`,
        },

        livetracking: {
            root: `${ROOTS.DASHBOARD}/location-management/tracking`,
            locationHistory: `${ROOTS.DASHBOARD}/location-management/tracking/location-history`,
            liveTracking: `${ROOTS.DASHBOARD}/location-management/tracking/live-tracking`,
            routeMaps: `${ROOTS.DASHBOARD}/location-management/tracking/route-maps`,
        },

        // tools

        tools: {
            root: `${ROOTS.DASHBOARD}/tools`,
            notification: `${ROOTS.DASHBOARD}/tools/notification`,
        },

        // enrollments

        device: {
            root: `${ROOTS.DASHBOARD}/device-management/device`,
            enrolldeviceandroid: `${ROOTS.DASHBOARD}/device-management/device/enroll-device-android`,
            enrolldeviceandroid2: `${ROOTS.DASHBOARD}/device-management/device/enroll-device-android2`,
            enrolldevicesteps: `${ROOTS.DASHBOARD}/device-management/device/enroll-device-steps`,
            tokensTab: `${ROOTS.DASHBOARD}/device-management/device/tokensTab`,
            tokenList: `${ROOTS.DASHBOARD}/device-management/device/tokenList`,
            enrolldevicedetails: (id) =>
                `${ROOTS.DASHBOARD}/device-management/device/${id}/enroll-device-details`,
        },

        groups: {
            root: `${ROOTS.DASHBOARD}/device-management/`,
            details: (group_id) => `${ROOTS.DASHBOARD}/device-management/${group_id}/groups`,
            list: `${ROOTS.DASHBOARD}/device-management/groups/list`,
            edit: `${ROOTS.DASHBOARD}/device-management/groups/edit`,
            create: `${ROOTS.DASHBOARD}/device-management/groups/create`,
        },

        users: {
            root: `${ROOTS.DASHBOARD}/device-management/users`,
            // details: `${ROOTS.DASHBOARD}/device-management/users/details`,
            details: (id) => `${ROOTS.DASHBOARD}/device-management/users/${id}/details`,
            list: `${ROOTS.DASHBOARD}/device-management/users/list`,
            edit: (id) => `${ROOTS.DASHBOARD}/device-management/users/${id}/edit`,
            create: `${ROOTS.DASHBOARD}/device-management/users/create`,
            cards: `${ROOTS.DASHBOARD}/device-management/users/cards`,
            bulkedit: `${ROOTS.DASHBOARD}/device-management/users/bulk-edit`,
        },

        selfenrollment: {
            root: `${ROOTS.DASHBOARD}/enrollments/self-enrollment`,
            edit: `${ROOTS.DASHBOARD}/enrollments/self-enrollment/edit`,
        },

        directoryservices: {
            root: `${ROOTS.DASHBOARD}/enrollments/directory-services`,
            edit: `${ROOTS.DASHBOARD}/enrollments/directory-services/edit`,
        },

        androiddevices: {
            root: `${ROOTS.DASHBOARD}/enrollments/android-devices`,
            knoxmobileenrollment: `${ROOTS.DASHBOARD}/enrollments/android-devices/knox-mobile-enrollment`,
            nfcenrollment: `${ROOTS.DASHBOARD}/enrollments/android-devices/nfc-enrollment`,
            zerotouchenrollment: `${ROOTS.DASHBOARD}/enrollments/android-devices/zero-touch-enrollment`,
        },

        cruxuemapp: {
            root: `${ROOTS.DASHBOARD}/enrollments/crux-uem-app`,
        },
        // reports
        reports: {
            root: `${ROOTS.DASHBOARD}/reports`,
        },

        // teamxcel
        administrativeboundary: {
            root: `${ROOTS.DASHBOARD}/uem-setting/administrative-boundary`,
            details: `${ROOTS.DASHBOARD}/uem-setting/administrative-boundary/details`,
            list: `${ROOTS.DASHBOARD}/uem-setting/administrative-boundary/list`,
            edit: `${ROOTS.DASHBOARD}/uem-setting/administrative-boundary/edit`,
            create: `${ROOTS.DASHBOARD}/uem-setting/administrative-boundary/create`,
        },
        teamxcelreports: {
            root: `${ROOTS.DASHBOARD}/team-xcel/teamxcel-reports`,
            projectreport: `${ROOTS.DASHBOARD}/team-xcel/teamxcel-reports/projectreport`,
            teamreport: `${ROOTS.DASHBOARD}/team-xcel/teamxcel-reports/teamreport`,
            employeereport: `${ROOTS.DASHBOARD}/team-xcel/teamxcel-reports/employeereport`,
            absentreport: `${ROOTS.DASHBOARD}/team-xcel/teamxcel-reports/absentreport`,
            clockinoutreport: `${ROOTS.DASHBOARD}/team-xcel/teamxcel-reports/clockinoutreport`,
        },
        employee: {
            root: `${ROOTS.DASHBOARD}/team-xcel/employee`,
            details: `${ROOTS.DASHBOARD}/team-xcel/employee/details`,
            list: `${ROOTS.DASHBOARD}/team-xcel/employee/list`,
            edit: `${ROOTS.DASHBOARD}/team-xcel/employee/edit`,
            create: `${ROOTS.DASHBOARD}/team-xcel/employee/create`,
            projectassign: `${ROOTS.DASHBOARD}/team-xcel/employee/projectassign`,
            editprojectassign: `${ROOTS.DASHBOARD}/team-xcel/employee/editprojectassign`,
        },
        enterpriseproject: {
            root: `${ROOTS.DASHBOARD}/team-xcel/enterprise-project`,
            details: `${ROOTS.DASHBOARD}/team-xcel/enterprise-project/details`,
            list: `${ROOTS.DASHBOARD}/team-xcel/enterprise-project/list`,
            employee: `${ROOTS.DASHBOARD}/team-xcel/enterprise-project/employee`,
            edit: `${ROOTS.DASHBOARD}/team-xcel/enterprise-project/edit`,
            create: `${ROOTS.DASHBOARD}/team-xcel/enterprise-project/create`,
        },
        office: {
            root: `${ROOTS.DASHBOARD}/team-xcel/office`,
            details: `${ROOTS.DASHBOARD}/team-xcel/office/details`,
            list: `${ROOTS.DASHBOARD}/team-xcel/office/list`,
            edit: `${ROOTS.DASHBOARD}/team-xcel/office/edit`,
            create: `${ROOTS.DASHBOARD}/team-xcel/office/create`,
        },
        projectteams: {
            root: `${ROOTS.DASHBOARD}/team-xcel/project-teams`,
            details: `${ROOTS.DASHBOARD}/team-xcel/project-teams/details`,
            list: `${ROOTS.DASHBOARD}/team-xcel/project-teams/list`,
            edit: `${ROOTS.DASHBOARD}/team-xcel/project-teams/edit`,
            create: `${ROOTS.DASHBOARD}/team-xcel/project-teams/create`,
            associate: `${ROOTS.DASHBOARD}/team-xcel/project-teams/associate`,
        },
        teamxceldashboard: {
            root: `${ROOTS.DASHBOARD}/team-xcel/teamxcel-dashboard`,
            dashboard: `${ROOTS.DASHBOARD}/team-xcel/teamxcel-dashboard/details`,
            // reports: `${ROOTS.DASHBOARD}/team-xcel/dashboard-and-reports/reports`,
            // edit: `${ROOTS.DASHBOARD}/team-xcel/dashboard-and-reports/edit`,
            // create: `${ROOTS.DASHBOARD}/team-xcel/dashboard-and-reports/create`,
        },

        product: {
            root: `/product`,
            checkout: `/product/checkout`,
            details: (id) => `/product/${id}`,
            demo: {
                details: `/product/${MOCK_ID}`,
            },
        },
        post: {
            root: `/post`,
            details: (title) => `/post/${paramCase(title)}`,
            demo: {
                details: `/post/${paramCase(MOCK_TITLE)}`,
            },
        },
        // AUTH
        auth: {
            amplify: {
                login: `${ROOTS.AUTH}/amplify/login`,
                verify: `${ROOTS.AUTH}/amplify/verify`,
                register: `${ROOTS.AUTH}/amplify/register`,
                newPassword: `${ROOTS.AUTH}/amplify/new-password`,
                forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
            },
            jwt: {
                login: `${ROOTS.AUTH}/jwt/login`,
                // loginUser: `${ROOTS.AUTHENTICATION}/auth-login`,
                register: `${ROOTS.AUTH}/jwt/register`,
            },
            firebase: {
                login: `${ROOTS.AUTH}/firebase/login`,
                verify: `${ROOTS.AUTH}/firebase/verify`,
                register: `${ROOTS.AUTH}/firebase/register`,
                forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
            },
            auth0: {
                login: `${ROOTS.AUTH}/auth0/login`,
            },
        },
        authDemo: {
            classic: {
                login: `${ROOTS.AUTH_DEMO}/classic/login`,
                register: `${ROOTS.AUTH_DEMO}/classic/register`,
                forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
                newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
                verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
            },
            modern: {
                login: `${ROOTS.AUTH_DEMO}/modern/login`,
                register: `${ROOTS.AUTH_DEMO}/modern/register`,
                forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
                newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
                verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
            },
        },
        // Authentication
        authentication: {
            authlogin: `${ROOTS.AUTHENTICATION}/auth-login`,
            authregister: `${ROOTS.AUTHENTICATION}/auth-register`,
            authforgotPassword: `${ROOTS.AUTHENTICATION}/auth-forgot-password`,
            authchangepassword: `${ROOTS.AUTHENTICATION}/auth-change-password`,
            authnewPassword: `${ROOTS.AUTHENTICATION}/auth-new-password`,
            authverify: `${ROOTS.AUTHENTICATION}/auth-verify`,
            authmfa: `${ROOTS.AUTHENTICATION}/auth-mfa`,
            authverifymfa: `${ROOTS.AUTHENTICATION}/auth-verify-mfa`,
        },
        // DASHBOARD
        dashboard: {
            root: ROOTS.DASHBOARD,
            mail: `${ROOTS.DASHBOARD}/mail`,
            chat: `${ROOTS.DASHBOARD}/chat`,
            blank: `${ROOTS.DASHBOARD}/blank`,
            kanban: `${ROOTS.DASHBOARD}/kanban`,
            calendar: `${ROOTS.DASHBOARD}/calendar`,
            fileManager: `${ROOTS.DASHBOARD}/file-manager`,
            permission: `${ROOTS.DASHBOARD}/permission`,
            general: {
                app: `${ROOTS.DASHBOARD}/app`,
                ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
                analytics: `${ROOTS.DASHBOARD}/analytics`,
                banking: `${ROOTS.DASHBOARD}/banking`,
                booking: `${ROOTS.DASHBOARD}/booking`,
                file: `${ROOTS.DASHBOARD}/file`,
            },
            // user
            user: {
                root: `${ROOTS.DASHBOARD}/site-administration/user`,
                new: `${ROOTS.DASHBOARD}/site-administration/user/new`,
                list: `${ROOTS.DASHBOARD}/site-administration/user/list`,
                cards: `${ROOTS.DASHBOARD}/site-administration/user/cards`,
                profile: (id) => `${ROOTS.DASHBOARD}/site-administration/user/${id}/profile`,
                account: `${ROOTS.DASHBOARD}/site-administration/user/account`,
                edit: `${ROOTS.DASHBOARD}/site-administration/user/edit`,
                demo: {
                    edit: `${ROOTS.DASHBOARD}/site-administration/user/${MOCK_ID}/edit`,
                },
            },

            roles: {
                root: `${ROOTS.DASHBOARD}/site-administration/roles`,
                list: `${ROOTS.DASHBOARD}/site-administration/roles/list`,
                edit: `${ROOTS.DASHBOARD}/site-administration/roles/edit`,
                create: `${ROOTS.DASHBOARD}/site-administration/roles/create`,
            },

            permissions: {
                root: `${ROOTS.DASHBOARD}/site-administration/permissions`,
                list: `${ROOTS.DASHBOARD}/site-administration/permissions/list`,
                edit: `${ROOTS.DASHBOARD}/site-administration/permissions/edit`,
                create: `${ROOTS.DASHBOARD}/site-administration/permissions/create`,
            },

            organisation: {
                root: `${ROOTS.DASHBOARD}/uem-setting/organisation`,
                edit: `${ROOTS.DASHBOARD}/uem-setting/organisation/edit`,
            },

            enterprises: {
                root: `${ROOTS.DASHBOARD}/uem-setting/enterprises`,
                details: (id) => `${ROOTS.DASHBOARD}/uem-setting/enterprises/${id}`,
                list: `${ROOTS.DASHBOARD}/uem-setting/enterprises/list`,
                edit: `${ROOTS.DASHBOARD}/uem-setting/enterprises/edit`,
                create: `${ROOTS.DASHBOARD}/uem-setting/enterprises/create`,
                createent: (id) => `${ROOTS.DASHBOARD}/uem-setting/enterprises/create/${id}`,
            },

            organisationhierarchy: {
                root: `${ROOTS.DASHBOARD}/uem-setting/organisation-hierarchy`,
                list: `${ROOTS.DASHBOARD}/uem-setting/organisation-hierarchy/list`,
                edit: `${ROOTS.DASHBOARD}/uem-setting/organisation-hierarchy/edit`,
                create: `${ROOTS.DASHBOARD}/uem-setting/organisation-hierarchy/create`,
            },
            // uem
            setting: {
                root: `${ROOTS.DASHBOARD}/uem-setting/setting`,
                edit: `${ROOTS.DASHBOARD}/uem-setting/setting/edit`,
            },

            termsofusedoc: {
                root: `${ROOTS.DASHBOARD}/uem-setting/terms-of-use-doc`,
                list: `${ROOTS.DASHBOARD}/uem-setting/terms-of-use-doc/list`,
                edit: `${ROOTS.DASHBOARD}/uem-setting/terms-of-use-doc/edit`,
                create: `${ROOTS.DASHBOARD}/uem-setting/terms-of-use-doc/create`,
            },

            // application-management
            apprepository: {
                root: `${ROOTS.DASHBOARD}/application-management/app-repository`,
                createtest: `${ROOTS.DASHBOARD}/application-management/app-repository/create-test`,
                applist: `${ROOTS.DASHBOARD}/application-management/app-repository/app-list`,
                webapps: `${ROOTS.DASHBOARD}/application-management/app-repository/web-apps`,
                organizeapps: `${ROOTS.DASHBOARD}/application-management/app-repository/organize-apps`,
                detail: `${ROOTS.DASHBOARD}/application-management/app-repository/detail`,
                details: (appId) =>
                    `${ROOTS.DASHBOARD}/application-management/app-repository/${appId}/detail`,
                edit: `${ROOTS.DASHBOARD}/application-management/app-repository/edit`,
                create: `${ROOTS.DASHBOARD}/application-management/app-repository/create`,
                distribute: `${ROOTS.DASHBOARD}/application-management/app-repository/distribute`,
            },

            appupdatepolicy: {
                root: `${ROOTS.DASHBOARD}/application-management/app-update-policy`,
                edit: `${ROOTS.DASHBOARD}/application-management/app-update-policy/edit`,
                list: `${ROOTS.DASHBOARD}/application-management/app-update-policy/list`,
                create: `${ROOTS.DASHBOARD}/application-management/app-update-policy/create`,
            },

            // content-management

            content: {
                root: `${ROOTS.DASHBOARD}/content-management/content`,
                list: `${ROOTS.DASHBOARD}/content-management/content/list`,
                edit: `${ROOTS.DASHBOARD}/content-management/content/edit`,
                create: `${ROOTS.DASHBOARD}/content-management/content/create`,
            },

            // device-mamagement

            devices: {
                root: `${ROOTS.DASHBOARD}/device-management/devices`,
                detail: (id) => `${ROOTS.DASHBOARD}/device-management/devices/${id}/detail`,
                list: `${ROOTS.DASHBOARD}/device-management/devices/list`,
                edit: `${ROOTS.DASHBOARD}/device-management/devices/edit`,
                details: (id) => `${ROOTS.DASHBOARD}/device-management/devices/${id}`,
                bulkedit: `${ROOTS.DASHBOARD}/device-management/devices/bulk-edit`,
                create: `${ROOTS.DASHBOARD}/device-management/devices/create`,
            },

            profiles: {
                root: `${ROOTS.DASHBOARD}/device-management/profiles`,
                list: `${ROOTS.DASHBOARD}/device-management/profiles/list`,
                edit: `${ROOTS.DASHBOARD}/device-management/profiles/edit`,
                create: `${ROOTS.DASHBOARD}/device-management/profiles/create`,
                detail: `${ROOTS.DASHBOARD}/device-management/profiles/details`,
                workprofile: `${ROOTS.DASHBOARD}/device-management/profiles/workprofile`,
            },

            certificates: {
                root: `${ROOTS.DASHBOARD}/device-management/certificates`,
                list: `${ROOTS.DASHBOARD}/device-management/certificates/list`,
                edit: `${ROOTS.DASHBOARD}/device-management/certificates/edit`,
                create: `${ROOTS.DASHBOARD}/device-management/certificates/create`,
                caserver: `${ROOTS.DASHBOARD}/device-management/certificates/ca-server`,
                templates: `${ROOTS.DASHBOARD}/device-management/certificates/templates`,
            },

            devicealerts: {
                root: `${ROOTS.DASHBOARD}/device-management/device-alerts`,
                edit: `${ROOTS.DASHBOARD}/device-management/device-alerts/edit`,
                create: `${ROOTS.DASHBOARD}/device-management/device-alerts/create`,
            },

            osupdates: {
                root: `${ROOTS.DASHBOARD}/device-management/os-updates`,
                list: `${ROOTS.DASHBOARD}/device-management/os-updates/list`,
                edit: `${ROOTS.DASHBOARD}/device-management/os-updates/edit`,
                create: `${ROOTS.DASHBOARD}/device-management/os-updates/create`,
            },

            enterprisesandgroups: {
                root: `${ROOTS.DASHBOARD}/device-management/enterprises-and-groups`,
            },

            scheduledevicescan: {
                root: `${ROOTS.DASHBOARD}/device-management/schedule-device-scan`,
                scannow: `${ROOTS.DASHBOARD}/device-management/schedule-device-scan/scan-now`,
            },

            // location-management

            // geofencepolicy: {
            //     root: `${ROOTS.DASHBOARD}/location-management/geo-fence-policy`,
            //     list: `${ROOTS.DASHBOARD}/location-management/geo-fence-policy/list`,
            //     edit: `${ROOTS.DASHBOARD}/location-management/geo-fence-policy/edit`,
            //     create: `${ROOTS.DASHBOARD}/location-management/geo-fence-policy/create`,
            //     geomaps: `${ROOTS.DASHBOARD}/location-management/geo-fence-policy/Maps`,
            // },

            // geofencerepository: {
            //     root: `${ROOTS.DASHBOARD}/location-management/geo-fence-repository`,
            //     list: `${ROOTS.DASHBOARD}/location-management/geo-fence-repository/list`,
            //     edit: `${ROOTS.DASHBOARD}/location-management/geo-fence-repository/edit`,
            //     create: `${ROOTS.DASHBOARD}/location-management/geo-fence-repository/create`,
            // },

            // livetracking: {
            //     root: `${ROOTS.DASHBOARD}/location-management/livetracking`,
            //     multi: `${ROOTS.DASHBOARD}/location-management/livetracking/multi`,
            // },

            // tools

            tools: {
                root: `${ROOTS.DASHBOARD}/tools`,
                notification: `${ROOTS.DASHBOARD}/tools/notification`,
            },

            // enrollments

            device: {
                root: `${ROOTS.DASHBOARD}/device-management/device`,
                enrolldeviceandroid: `${ROOTS.DASHBOARD}/device-management/device/enroll-device-android`,
                enrolldeviceandroid2: `${ROOTS.DASHBOARD}/device-management/device/enroll-device-android2`,
                enrolldevicesteps: `${ROOTS.DASHBOARD}/device-management/device/enroll-device-steps`,

                enrolldevicedetails: (id) =>
                    `${ROOTS.DASHBOARD}/device-management/device/${id}/enroll-device-details`,
            },

            enrollmentTokens: {
                root: `${ROOTS.DASHBOARD}/device-management/enrollmentTokens`,
                tokensTab: `${ROOTS.DASHBOARD}/device-management/enrollmentTokens/tokensTab`,
                tokenList: `${ROOTS.DASHBOARD}/device-management/enrollmentTokens/tokenList`,
            },

            groups: {
                root: `${ROOTS.DASHBOARD}/device-management/groups`,
                details: (group_id) => `${ROOTS.DASHBOARD}/device-management/${group_id}/groups`,
                list: `${ROOTS.DASHBOARD}/device-management/groups/list`,
                edit: `${ROOTS.DASHBOARD}/device-management/groups/edit`,
                create: `${ROOTS.DASHBOARD}/device-management/groups/create`,
            },

            users: {
                root: `${ROOTS.DASHBOARD}/device-management/users`,
                // details: `${ROOTS.DASHBOARD}/device-management/users/details`,
                details: (id) => `${ROOTS.DASHBOARD}/device-management/users/${id}/details`,
                list: `${ROOTS.DASHBOARD}/device-management/users/list`,
                edit: (id) => `${ROOTS.DASHBOARD}/device-management/users/${id}/edit`,
                create: `${ROOTS.DASHBOARD}/device-management/users/create`,
                bulkedit: `${ROOTS.DASHBOARD}/device-management/users/bulk-edit`,
            },

            selfenrollment: {
                root: `${ROOTS.DASHBOARD}/enrollments/self-enrollment`,
                edit: `${ROOTS.DASHBOARD}/enrollments/self-enrollment/edit`,
            },

            directoryservices: {
                root: `${ROOTS.DASHBOARD}/enrollments/directory-services`,
                edit: `${ROOTS.DASHBOARD}/enrollments/directory-services/edit`,
            },

            androiddevices: {
                root: `${ROOTS.DASHBOARD}/enrollments/android-devices`,
                knoxmobileenrollment: `${ROOTS.DASHBOARD}/enrollments/android-devices/knox-mobile-enrollment`,
                nfcenrollment: `${ROOTS.DASHBOARD}/enrollments/android-devices/nfc-enrollment`,
                zerotouchenrollment: `${ROOTS.DASHBOARD}/enrollments/android-devices/zero-touch-enrollment`,
            },

            cruxuemapp: {
                root: `${ROOTS.DASHBOARD}/enrollments/crux-uem-app`,
            },
            // reports
            reports: {
                root: `${ROOTS.DASHBOARD}/reports`,
            },

            product: {
                root: `${ROOTS.DASHBOARD}/product`,
                new: `${ROOTS.DASHBOARD}/product/new`,
                details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
                edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
                demo: {
                    details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
                    edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
                },
            },
            invoice: {
                root: `${ROOTS.DASHBOARD}/invoice`,
                new: `${ROOTS.DASHBOARD}/invoice/new`,
                details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
                edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
                demo: {
                    details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
                    edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
                },
            },
            post: {
                root: `${ROOTS.DASHBOARD}/post`,
                new: `${ROOTS.DASHBOARD}/post/new`,
                details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
                edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
                demo: {
                    details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
                    edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
                },
            },
            order: {
                root: `${ROOTS.DASHBOARD}/order`,
                details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
                demo: {
                    details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
                },
            },
            job: {
                root: `${ROOTS.DASHBOARD}/job`,
                new: `${ROOTS.DASHBOARD}/job/new`,
                details: (id) => `${ROOTS.DASHBOARD}/job/${id}`,
                edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
                demo: {
                    details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
                    edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
                },
            },
            tour: {
                root: `${ROOTS.DASHBOARD}/tour`,
                new: `${ROOTS.DASHBOARD}/tour/new`,
                details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
                edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
                demo: {
                    details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
                    edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
                },
            },
        },
    },
};
